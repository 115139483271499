import { ShipmentData } from 'transfire-api';
import Typesense from 'typesense';

const searchClient = new Typesense.Client({
  nodes: [
    {
      host: import.meta.env.VITE_TYPESENSE_NODES,
      port: 443,
      protocol: 'https',
    },
  ],
  apiKey: import.meta.env.VITE_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2,
});

searchClient.collections().create({
  name: 'shipments',
  enable_nested_fields: true,
  fields: [
    { name: 'tracking_id', type: 'string' },
    { name: 'customer_tracking', type: 'string' },
    { name: 'description', type: 'string' },
    { name: 'destination_address', type: 'object' },
    { name: 'date_created', type: 'int64' },
  ],
  default_sorting_field: 'date_created',
});

/**
 *
 * @param searchString
 * @param loadIds - specify loads to search within. Searches all loads if not specified.
 * @returns
 */
export const searchTrackingUnits = async (
  searchString: string,
  customerId?: string,
  loadIds?: string[]
) => {
  const filters = [];

  if (customerId) {
    filters.push(`customer.id:=\`${customerId}\``);
  }

  if (loadIds && loadIds.length > 0) {
    filters.push(`incoming_load_id:=[${loadIds.map((id) => `\`${id}\``).join(',')}]`);
  }

  return searchClient
    .collections<ShipmentData>('shipments')
    .documents()
    .search({
      q: searchString,
      query_by: 'tracking_id,customer_tracking,description,destination_address',
      sort_by: 'date_created:desc',
      num_typos: 1,
      per_page: 6,
      filter_by: filters.join(' && '),
    });
};
