import ReactDOM from 'react-dom';
import { Suspense, useState, lazy } from 'react';

import { LayoutSplashScreen } from '_metronic/layout/core';
import { UpdateWebsite } from 'app/modules/toasts/UpdateWebsite';

// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import './index.css';
import { AuthProvider } from './app/modules/auth';
import '@popperjs/core';
import './app/data-interface/initializeSentry';
import { registerSW } from 'virtual:pwa-register';

import './app/data-interface/typesense';

const UpdateWebsiteToast = () => {
  const [showUpdateToast, setShowUpdateToast] = useState(false);
  if ('serviceWorker' in navigator) {
    // && !/localhost/.test(window.location)) {
    const updateSW = registerSW({
      onNeedRefresh() {
        setShowUpdateToast(true);
      },
      // onOfflineReady() { },
    });

    return (
      <UpdateWebsite
        show={showUpdateToast}
        hide={() => setShowUpdateToast(false)}
        updateSW={updateSW}
      />
    );
  }
  return <></>;
};

const AppRoutes = lazy(() => import('./app/routing/AppRoutes'));

ReactDOM.render(
  <Suspense fallback={<LayoutSplashScreen />}>
    <UpdateWebsiteToast />
    <MetronicI18nProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </MetronicI18nProvider>
  </Suspense>,
  document.getElementById('root')
);
