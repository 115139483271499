import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { User as UserAuth } from 'firebase/auth';
import * as Sentry from '@sentry/react';

import { LayoutSplashScreen } from '_metronic/layout/core';
import { logout, onAuthStateChanged } from 'app/data-interface';
import { User } from 'transfire-api';

type AuthContextProps = {
  currentUser: User | null;
  setCurrentUser: Dispatch<SetStateAction<User | null>>;
  logout: () => Promise<void>;
  refreshUser: () => void;
};

const initAuthContextPropsState = {
  currentUser: null,
  setCurrentUser: () => {},
  logout: logout,
  refreshUser: () => {
    return;
  },
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

function setSentryUser(user: User | null) {
  if (!user) {
    Sentry.setUser(null);
    return;
  }

  Sentry.setUser({
    id: user.uid,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    roles: user.roles,
  });
}

const AuthProvider: FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  async function refreshUser() {
    if (!currentUser?.uid) return;

    try {
      const userResult = await User.get(currentUser?.uid);
      setCurrentUser(userResult);
      setSentryUser(userResult);
    } catch (e: any) {
      console.log(e);
    }
    return;
  }

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, logout, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC = ({ children }) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { setCurrentUser } = useAuth();
  const unsubRef = useRef<(() => void) | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(async (userAuth: UserAuth) => {
      setShowSplashScreen(true);

      function afterAuthLogout() {
        unsubRef.current?.();
        setCurrentUser(null);
        setSentryUser(null);
        setShowSplashScreen(false);
        navigate('/auth');
      }

      function userDocChanged(user: User | null) {
        if (!user) {
          // user was deleted
          logout();
          afterAuthLogout();
          return;
        }

        setCurrentUser(user);
        setSentryUser(user);
        setShowSplashScreen(false);
      }

      if (userAuth) {
        unsubRef.current = User.get(userAuth.uid, userDocChanged);
      } else {
        afterAuthLogout();
      }
    });

    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
