import { httpsCallable } from 'firebase/functions';

import { functions } from './firebaseApp';

import {
  CreateInvoiceParams,
  CreateInvoiceResponse,
  CreateGroupInvoiceParams,
  CreateGroupInvoiceResponse,
  DeleteInvoiceParams,
  FunctionResponse,
  FinishInboundScanProps,
  FinishInboundScanResponse,
  ReviewTripParams,
  RecordTripParams,
} from 'transfire-api';

export const sendInvoiceEDI = httpsCallable<Object[], {}>(functions, 'Integrations-graingerEDI');

export const finishInboundScan = httpsCallable<FinishInboundScanProps, FinishInboundScanResponse>(
  functions,
  'IncomingLoad-finishInboundScan'
);

export const onCreateInvoice = httpsCallable<CreateInvoiceParams, CreateInvoiceResponse>(
  functions,
  'Invoices-onCreateInvoice'
);
export const onCreateGroupInvoice = httpsCallable<
  CreateGroupInvoiceParams,
  CreateGroupInvoiceResponse
>(functions, 'Invoices-onCreateGroupInvoice');
export const onDeleteInvoice = httpsCallable<DeleteInvoiceParams, FunctionResponse>(
  functions,
  'Invoices-onDeleteInvoice'
);

export const onRecordTrip = httpsCallable<RecordTripParams, FunctionResponse>(
  functions,
  'DedicatedTrips-onRecord'
);
export const onReviewTrip = httpsCallable<ReviewTripParams, FunctionResponse>(
  functions,
  'DedicatedTrips-onReview'
);
