import React, { createContext, useContext, useState, useEffect } from 'react';
import { DefaultLayoutConfig } from './DefaultLayoutConfig';

import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup';

import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels';
import { useAuth } from 'app/modules/auth';

export interface LayoutContextModel {
  config: ILayout;
  setToCustomLayout: (name?: 'driver') => void;
  classes: ILayoutCSSClasses;
  attributes: ILayoutHTMLAttributes;
  cssVariables: ILayoutCSSVariables;
  setLayout: (config: LayoutSetup) => void;
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig(),
  setToCustomLayout: () => {},
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
});

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex');
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none');
  }
};

const LayoutProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState(LayoutSetup.config);
  const [classes, setClasses] = useState(LayoutSetup.classes);
  const [attributes, setAttributes] = useState(LayoutSetup.attributes);
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables);
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen();
    const bodyClasses = Array.from(document.body.classList);
    bodyClasses.forEach((cl) => document.body.classList.remove(cl));
    LayoutSetup.updatePartialConfig(_themeConfig);
    setConfig(Object.assign({}, LayoutSetup.config));
    setClasses(LayoutSetup.classes);
    setAttributes(LayoutSetup.attributes);
    setCSSVariables(LayoutSetup.cssVariables);
    disableSplashScreen();
    // setTimeout(() => {
    //   disableSplashScreen()
    // }, 500)
  };

  const { currentUser } = useAuth();

  /**
   * Change the current layout to a pre-defined custom layout
   *
   * Call without param to reset the layout to default
   */
  const setToCustomLayout = (name?: 'driver') => {
    const newLayout = DefaultLayoutConfig();

    if (name === 'driver') {
      newLayout.aside.display = false;
      newLayout.header.display = false;
      newLayout.tabBar.display = true;
    }

    setLayout(newLayout);
    return;
  };

  // listen to changes to the current user and set the layout for the user type
  useEffect(() => {
    const layoutType = currentUser?.isOnlyDriver() ? 'driver' : undefined;

    setToCustomLayout(layoutType);
  }, [currentUser]);

  const value: LayoutContextModel = {
    config,
    setToCustomLayout,
    classes,
    attributes,
    cssVariables,
    setLayout,
  };

  useEffect(() => {
    disableSplashScreen();
  }, []);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
  return useContext(LayoutContext);
}
