import * as transfireAPI from 'app/data-interface';

export async function login(email: string, password: string): Promise<void> {
  await transfireAPI.login(email, password);
  return;
}

export async function requestPassword(email: string) {
  return await transfireAPI.resetPassword(email);
}
