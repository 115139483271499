import React from 'react';

import Toast from 'react-bootstrap/Toast';

interface Props {
  updateSW: () => void;
  show: boolean;
  hide: () => void;
}

export function UpdateWebsite({ updateSW, show, hide }: Props) {
  const clickHandler = () => {
    updateSW();
    hide();
  };

  return (
    <div
      style={{ zIndex: 9998 }}
      className="toast-container bottom-0 start-50 translate-middle-x pb-10 fixed-bottom"
    >
      <Toast show={show} onClose={hide}>
        <Toast.Header>
          <strong className="me-auto">A new version of the site is available.</strong>
          <button className="btn btn-primary btn-sm" onClick={clickHandler}>
            Update
          </button>
        </Toast.Header>
      </Toast>
    </div>
  );
}
