import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import * as FirebaseFirestore from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import { TransfireStoreClass } from 'transfire-api';
import { TransfireStoreClient } from 'transfire-store-client';

TransfireStoreClient.FS = FirebaseFirestore;

// Web app's Firebase configuration
const cloudApp = initializeApp(
  {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY || 'AIzaSyD6zA0RA7qLE-zin4QEsEp60jp9yG4bkSI',
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
  },
  import.meta.env.MODE
);

const db = FirebaseFirestore.getFirestore(cloudApp);
const auth = getAuth(cloudApp);
const storage = getStorage(cloudApp);
const functions = getFunctions(cloudApp, 'us-west2');

// enableIndexedDbPersistence(db);

if (import.meta.env.VITE_EMULATE) {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectStorageEmulator(storage, 'localhost', 9199);
  FirebaseFirestore.connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

// initialize class database

const transfireDb = new TransfireStoreClient(db);
TransfireStoreClass.setFirestore(transfireDb);

export { auth, storage, db, transfireDb, functions };
