import { auth, functions, storage } from 'app/data-interface/firebaseApp';

import {
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getIdToken,
  updateEmail,
  updatePassword,
  Unsubscribe,
} from 'firebase/auth';

import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { CustomClaims, User } from 'transfire-api';

export function onAuthStateChanged(fn: any): Unsubscribe {
  return auth.onAuthStateChanged(fn);
}

export async function login(email: string, password: string) {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  const userClaims: CustomClaims = ((await user.getIdTokenResult()) as any).claims;

  if (!userClaims.roles) {
    auth.signOut();
    throw Error('Your account has not been assigned a role.');
  }

  return { ...user, ...userClaims };
}

export async function logout() {
  return auth.signOut();
}

export async function refreshIdToken() {
  if (!auth.currentUser) return;
  return await getIdToken(auth.currentUser);
}

/**
 * Update my current user's password using Client SDK
 *
 * @param {string} currentEmail
 * @param {string} newEmail
 * @param {string} confirmPassword - current password
 */
export async function updateMyPassword({
  currentEmail,
  currentPassword,
  newPassword,
}: {
  currentEmail: string;
  currentPassword: string;
  newPassword: string;
}) {
  const userCredential = await signInWithEmailAndPassword(auth, currentEmail, currentPassword);
  return updatePassword(userCredential.user, newPassword);
}

export interface IUpdateUserEmail {
  uid?: string;
  email?: string;
  newEmail: string;
  confirmPassword?: string;
}
/**
 * Update a user's email address using the Admin SDK if a uid is provided or the current user's email if an email and password are provided
 */
export async function updateUserEmail({ uid, email, newEmail, confirmPassword }: IUpdateUserEmail) {
  if (email && confirmPassword) {
    const { user } = await signInWithEmailAndPassword(auth, email, confirmPassword);
    await User.update(user.uid, { email: newEmail });

    return updateEmail(user, newEmail);
  } else if (uid) {
    return httpsCallable<IUpdateUserEmail, string>(
      functions,
      'Users-updateEmail'
    )({ uid, newEmail });
  }

  throw new Error('Invalid updateUserEmail arguments.');
}

/**
 * Upload an image to cloud storage and return the download URL
 */
export async function uploadProfilePic(dataURL: string, uid: string) {
  const profilePicRef = ref(storage, 'user_profile_pictures/' + uid + '.jpeg');
  await uploadString(profilePicRef, dataURL, 'data_url');
  return getDownloadURL(profilePicRef);
}

export interface IUpdateUserPassword {
  newPassword: string;
  uid?: string;
  email?: string;
  currentPassword?: string;
  passwordConfirmation?: string;
}
/**
 * Update a user's password using Admin SDK if a uid is provided or the current user's password if an email and password are provided
 */
export async function updateUserPassword({
  newPassword,
  uid,
  email,
  currentPassword,
}: IUpdateUserPassword) {
  if (email && currentPassword) {
    const userCredential = await signInWithEmailAndPassword(auth, email, currentPassword);
    return updatePassword(userCredential.user, newPassword);
  } else if (uid) {
    return httpsCallable<IUpdateUserPassword, string>(
      functions,
      'Users-updatePassword'
    )({ uid, newPassword });
  }

  throw new Error('Invalid updateUserEmail arguments.');
}

interface ICreateUserAccount {
  email: string;
  password: string;
  roles: string[];
}
/**
 * Create an account using the Admin SDK, bypassing any automatic sign-in done by the client SDK
 * @param {string} email
 * @param {string} password
 * @param {string[]} roles
 */
export async function createUserAccount(props: ICreateUserAccount) {
  const create = httpsCallable<ICreateUserAccount, { newUid: string }>(
    functions,
    'Users-createNewUser'
  );
  const response = await create(props);

  return response.data;
}

export async function resetPassword(email: string) {
  await sendPasswordResetEmail(auth, email);
}

export async function sendVerificationEmail(email: string, password: string) {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  auth.signOut();
  await sendEmailVerification(user);
}
